<template>
  <div id="pageUserProfile" class="page insideApp">
    <h1>{{ $t("header.profile") }}</h1>
    <div
      id="pageUserProfile-tabs"
      class="content ui-tabs ui-widget ui-widget-content ui-corner-all"
    >
      <div class="topPageMenu">
        <router-link :to="{ name: 'modules' }" v-html="$t('lobby.link')" />
      </div>
      <ul
        class="ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all"
      >
        <li
          :class="selectedTab === 1 && 'ui-tabs-active ui-state-active'"
          class="ui-state-default ui-corner-top"
          @click="profileTabClick(1)"
        >
          <a :class="!userProfile.isReady && 'notReadyAlert'">
            <span>
              <span>
                <span>{{ $t("header.account") }}</span>
              </span>
            </span>
          </a>
        </li>
        <li
          :class="selectedTab === 2 && 'ui-tabs-active ui-state-active'"
          class="ui-state-default ui-corner-top"
          @click="profileTabClick(2)"
        >
          <a>
            <span>
              <span>
                <span>{{ $t("gps.label") }}</span>
              </span>
            </span>
          </a>
        </li>
        <li
          :class="selectedTab === 3 && 'ui-tabs-active ui-state-active'"
          class="ui-state-default ui-corner-top"
          @click="profileTabClick(3)"
        >
          <a :class="!userProfile.hasElogKey && 'notReadyAlert'">
            <span>
              <span>
                <span>{{ $t("elogkey.label") }}</span>
              </span>
            </span>
          </a>
        </li>
      </ul>
      <div
        v-if="selectedTab === 1"
        class="ui-tabs-panel ui-widget-content ui-corner-bottom"
      >
        <form id="accountForm" @submit.prevent="saveUser" autocomplete="off">
          <fieldset>
            <GenericInput
              v-model="user.username"
              :label="$t('fin.label')"
              :required="true"
              :finValidator="true"
              :refeshError="refreshError"
              type="text"
              disabled
              @error="checkError"
            />
            <br />
            <Select
              v-model="user.timezone"
              :label="$t('timezone.label')"
              :required="true"
              :options="$t('timezone.items')"
            />
            <br />
            <GenericInput
              v-model="user.firstname"
              :label="$t('fields.firstname')"
              :required="true"
              :maxlength="30"
              :refeshError="refreshError"
              type="text"
              @error="checkError"
            />
            <GenericInput
              v-model="user.lastname"
              :label="$t('fields.lastname')"
              :required="true"
              :maxlength="30"
              :refeshError="refreshError"
              type="text"
              @error="checkError"
            />
            <br />
            <GenericInput
              v-model="user.email"
              :label="$t('fields.email')"
              :required="true"
              :refeshError="refreshError"
              type="email"
              @error="checkError"
            />
            <br />
            <GenericInput
              v-model="user.phoneNumber"
              :label="$t('fields.phone')"
              :phoneUS="true"
              :required="true"
              :refeshError="refreshError"
              type="phone"
              @error="checkError"
            />
            <GenericInput
              v-model="user.address"
              :label="$t('fields.address')"
              :required="true"
              :maxlength="200"
              :refeshError="refreshError"
              type="text"
              @error="checkError"
            />
            <Select
              v-model="user.province"
              :label="$t('province.label')"
              :required="true"
              :options="$t('province.items')"
            />
            <Select
              v-model="user.dfoRegion"
              :label="$t('editTrip.subscriptionStep.dfo')"
              :help="$t('editTrip.subscriptionStep.dfoHelp')"
              :options="$t('dfoRegion.items')"
              :required="true"
              :refeshError="refreshError"
              @error="checkError"
            />
          </fieldset>
          <fieldset name="Security Questions">
            <Select
              v-model="user.securityQuestion1"
              :label="$t('security.label.question', { num: 1 })"
              :options="systemsLists.securityQuestions"
              :errorMsg="$t('security.errorMsg')"
              :arrayValidator="[user.securityQuestion3, user.securityQuestion2]"
              :refeshError="refreshError"
              @error="checkError"
            />
            <GenericInput
              v-model="user.answer1"
              :label="$t('security.label.answer')"
              :maxlength="250"
              :required="true"
              :refeshError="refreshError"
              type="text"
              @error="checkError"
            />
            <br />
            <Select
              v-model="user.securityQuestion2"
              :label="$t('security.label.question', { num: 2 })"
              :options="systemsLists.securityQuestions"
              :errorMsg="$t('security.errorMsg')"
              :arrayValidator="[user.securityQuestion1, user.securityQuestion3]"
              :refeshError="refreshError"
              @error="checkError"
            />
            <GenericInput
              v-model="user.answer2"
              :label="$t('security.label.answer')"
              :maxlength="250"
              :required="true"
              :refeshError="refreshError"
              type="text"
              @error="checkError"
            />
            <br />
            <Select
              v-model="user.securityQuestion3"
              :label="$t('security.label.question', { num: 3 })"
              :options="systemsLists.securityQuestions"
              :errorMsg="$t('security.errorMsg')"
              :arrayValidator="[user.securityQuestion1, user.securityQuestion2]"
              :refeshError="refreshError"
              @error="checkError"
            />
            <GenericInput
              v-model="user.answer3"
              :label="$t('security.label.answer')"
              :maxlength="250"
              :required="true"
              :refeshError="refreshError"
              type="text"
              @error="checkError"
            />
          </fieldset>
          <fieldset>
            <PasswordInput
              :label="$t('fields.password')"
              :disabled="true"
              :changePassword="true"
              value="password"
              autocomplete="current-password"
            />
          </fieldset>
          <button
            :class="!isConnectedOnline && 'deactivated'"
            class="firstnameLastnameBtn"
            type="submit"
          >
            {{ $t("save") }}
          </button>
        </form>
      </div>
      <div
        v-else-if="selectedTab === 2"
        class="ui-tabs-panel ui-widget-content ui-corner-bottom"
      >
        <form id="testGPSform" method="post" novalidate autocomplete="off">
          <fieldset>
            <p class="uneditable" v-html="$t('gps.intro')" />
          </fieldset>
          <GeopositionWidget
            :hasNafo="$const.OPTIONAL"
            :hasGrid="$const.OPTIONAL"
          />
        </form>
      </div>
      <div
        v-else-if="selectedTab === 3"
        id="pageUserProfile-tabs-3"
        class="ui-tabs-panel ui-widget-content ui-corner-bottom"
      >
        <p class="elogKeyTopMessage" v-html="$t('elogkey.intro')"></p>
        <h2>{{ $t("elogkey.info.heading") }}</h2>
        <ul class="mustknow">
          <li
            v-for="(item, index) in $t('elogkey.info.items')"
            v-html="item"
            :key="index"
          />
        </ul>
        <h2>{{ $t("elogkey.getKey.heading") }}</h2>
        <ul class="instructions">
          <li
            v-for="(item, index) in $t('elogkey.getKey.items')"
            v-html="item"
            :key="index"
          />
        </ul>
        <form autocomplete="off" @submit.prevent="submitElogkey">
          <p class="serverSaveSuccess success" style="display: none;">
            ELOG key successfully saved
          </p>
          <p class="serverSaveError error" style="display: none;">
            An error occurred while saving
          </p>
          <fieldset class="elogkey-wrapper fieldWrapper">
            <label>{{ $t("elogkey.label") }}</label>
            <button
              v-if="canPasteClipboard"
              class="pasteKeyBtn"
              @click="getClipboard"
            >
              {{ $t("elogkey.paste") }}
            </button>
            <input class="elogkey" v-model="elogkey" type="text" />
          </fieldset>
          <button
            :class="!isConnectedOnline && 'deactivated'"
            class="elogkeyBtn"
            type="submit"
          >
            {{ $t("elogkey.save") }}
          </button>
        </form>
        <h3 class="help">{{ $t("elogkey.help.heading") }}</h3>
        <p
          v-for="(item, index) in $t('elogkey.help.items')"
          v-html="item"
          :key="index"
          class="help"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import GeopositionWidget from "@/components/widgets/GeopositionWidget.vue";
import Select from "@/components/Select.vue";
import PasswordInput from "@/components/widgets/PasswordInput.vue";

export default {
  name: "userprofile",
  components: {
    GenericInput,
    GeopositionWidget,
    PasswordInput,
    Select
  },
  props: {
    tab: {
      type: [String, Number], //Il faut les deux types, car le browser envoie un string et non un number quand la page est rafraichi.
      required: false
    }
  },
  data() {
    return {
      selectedTab: 1,
      error: {},
      user: {},
      refreshError: 0,
      elogkey: "",
      canPasteClipboard:
        this.$navigator.clipboard &&
        typeof this.$navigator.clipboard.readText === "function"
    };
  },
  computed: {
    ...mapState({
      userProfile: state => state.userProfile,
      systemsLists: state => state.systemsLists,
      isConnectedOnline() {
        return this.$store.getters.IS_APP_CONNECTED_ONLINE;
      }
    })
  },
  methods: {
    ...mapActions(["saveUserProfile"]),
    profileTabClick(tab) {
      // this.selectedTab = tab;
      this.$router.replace({ name: "userprofile", params: { tab: tab } });
    },
    onlinePopupError() {
      this.$store.dispatch(
        "alert/error",
        this.$store.getters.GET_CURRENT_ONLINE_ERROR
      );
    },
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    refreshUser() {
      this.user = JSON.parse(JSON.stringify(this.userProfile));
    },
    saveUser(e) {
      // if (!this.isConnectedOnline) {
      //   this.onlinePopupError();
      //   return;
      // }
      e.preventDefault();
      const vm = this;
      const gotoModules = function() {
        vm.$store.dispatch("alert/success", vm.$i18n.t("message.save_success"));
        vm.$router.push({ name: "modules" });
        return Promise.resolve(true);
      };
      vm.refreshForm();
      vm.$nextTick(() => {
        if (vm.$hasInputErrorAndScroll()) {
          return;
        }
        vm.saveUserProfile(vm.user)
          .then(gotoModules)
          .catch(error => {
            vm.$alertUserApiError(error);
          });
      });
    },
    refreshForm() {
      this.refreshError += 1;
    },
    getClipboard(e) {
      e.preventDefault();
      navigator.clipboard
        .readText()
        .then(text => {
          this.elogkey = text;
        })
        .catch(err => {
          console.log("Something went wrong while pasting elogkey", err); // eslint-disable-line no-console
        });
    },
    submitElogkey() {
      // if (!this.isConnectedOnline) {
      //   this.onlinePopupError();
      //   return;
      // }
      const vm = this;
      const gotoModules = function() {
        vm.$router.push({ name: "modules" });
        return Promise.resolve(true);
      };
      vm.$store
        .dispatch("saveElogKey", vm.elogkey)
        .then(gotoModules)
        .catch(error => {
          vm.$alertUserApiError(error);
        });
    }
  },
  created() {
    this.refreshUser();
  },
  mounted() {
    this.refreshUser();
    // auto select tab on params, use an int value
    if (this.tab) {
      const tab = parseInt(this.tab);
      if (!isNaN(tab)) {
        this.selectedTab = tab;
      }
    }
  },
  watch: {
    tab() {
      const tab = parseInt(this.tab);
      if (!isNaN(tab)) {
        this.selectedTab = tab;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#pageUserProfile-tabs {
  position: relative;
  > ul {
    li {
      display: inline-block;
      background-color: $darkColor;
      margin: 2px -2px 0px -2px;
      border-left: white solid 2px;
      max-width: 25%;
      &:first-of-type {
        margin-left: 0px;
        border-left: none;
      }
    }
    & {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    * {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    li {
      a {
        display: inline-block;
        height: 55px;
        color: white;
        padding: 0px 10px 0px 10px;
        margin-top: 4px;
        margin-bottom: -3px;
        font-size: 1.2em;
        @media screen and (max-width: 400px) {
          font-size: 1em;
          padding-left: 4px;
        }
        & {
          outline: none;
        }
        &:hover {
          text-decoration: none;
        }
      }
      &.ui-state-hover:not(.ui-state-active) a:hover {
        background-color: white;
        color: $darkColor;
      }
      &.ui-state-focus:not(.ui-state-active) a:hover {
        background-color: lightgray;
        color: $darkColor;
      }
      &.ui-state-active a {
        background-color: white;
        color: $darkColor;
        cursor: default;
      }
    }
    a > span {
      display: table;
      overflow: hidden;
      height: 100%;
      width: 100%;
      > span {
        display: table-cell;
        vertical-align: middle;
        > span {
          display: inline;
          float: left;
        }
      }
    }
  }
}
.ui-tabs-panel {
  background-color: white;
}
#pageUserProfile-tabs > div {
  position: relative;
}
.eraseBrowser {
  font-size: 0.65em;
  text-align: center;
  margin-top: 16em;
}
form p > a {
  margin-top: 3px;
}

#pageUserProfile-tabs-3 {
  form {
    margin-bottom: 0px;
  }
  & {
    padding: 5%;
  }
  h2 {
    color: black;
    font-weight: bold;
    font-size: 1.5em;
    margin-top: 25px;
    margin-bottom: 20px;
    @media (max-width: 540px) {
      font-size: 1.3em;
      text-align: center;
    }
  }
  h3 {
    color: $darkColor;
    font-weight: bold;
    margin-top: 20px;
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  p {
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.2em;
  }
  ul {
    li {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 16px;
      padding-left: 4px;
    }
  }
  ul.mustknow {
    li {
      list-style-type: disc;
    }
  }
  ul.instructions {
    li {
      list-style-type: decimal;
    }
  }
  .help {
    font-size: 0.9em;
  }
}
.elogKeyTopMessage.elogKeyIsNeeded {
  font-weight: bold;
  font-size: 1.1em;
  color: red;
}
li.ui-state-active a.notReadyAlert,
li:not(.ui-state-active) a.notReadyAlert {
  animation-name: notReadyAlert-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: notReadyAlert-animation;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: notReadyAlert-animation;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
}

.ui-state-active a.notReadyAlert {
  color: red;
}
.ui-tabs-nav {
  li:hover > a {
    background-color: lightgray;
  }
}

@keyframes notReadyAlert-animation {
  0% {
    background: red;
    color: white;
    text-shadow: 2px 2px 10px red;
  }
  50% {
    background: white;
    color: white;
    text-shadow: 2px 2px 10px red;
  }
  100% {
    background: red;
    color: white;
    text-shadow: 2px 2px 10px red;
  }
}
@-webkit-keyframes notReadyAlert-animation {
  0% {
    background: red;
    color: white;
    text-shadow: 2px 2px 10px red;
  }
  50% {
    background: white;
    color: white;
    text-shadow: 2px 2px 10px red;
  }
  100% {
    background: red;
    color: white;
    text-shadow: 2px 2px 10px red;
  }
}
@-moz-keyframes notReadyAlert-animation {
  0% {
    background: red;
    color: white;
    text-shadow: 2px 2px 10px red;
  }
  50% {
    background: white;
    color: white;
    text-shadow: 2px 2px 10px red;
  }
  100% {
    background: red;
    color: white;
    text-shadow: 2px 2px 10px red;
  }
}
</style>
